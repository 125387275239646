import React, { useState, useEffect } from 'react'
import moment from "moment"
import { Button, Row, Table, Spin, Typography } from 'antd'
import { useReceiptsDispatch, useReceiptsState } from "../../contexts/receipts";
import { useTokenState } from '../../contexts/tokens'
import { actions } from "../../contexts/receipts/actions";
import { User, Receipt, ReceiptTypeNames } from "../../types/index";
import { DATE_FORMAT_STRINGS } from "../../helpers/constants" 

function TransactionTable(props: { users:[] }) {

  const { users } = props

  const {
    receipts,
    isReceiptsLoading,
  } = useReceiptsState();

  const {
    tokenOwnership,
    tokenRedemption,
    token,
    tokenDestroyed,
  } = useTokenState()

  const dispatch = useReceiptsDispatch();

  const data = receipts.map((r:Receipt) => {
    const displayName = (addr:string) => {
      const user:any = users.find((u:User) => u.userAddress == addr)
      if (user) { return `${user.commonName} - ${user.organization}` }
      else { 
        if (addr == '0000000000000000000000000000000000000000') { return 'RESERVE' }
        else { return addr }
      }
    }

    return {
      key: r.id,
      ...r,
      value: (parseInt(r._value)/100).toLocaleString(),
      to: displayName(r._to),
      from: displayName(r._from),
      type: ReceiptTypeNames[r.type],
      timestamp: moment.unix(r.timestamp).format(DATE_FORMAT_STRINGS.TIMESTAMP)
    }
  }).reverse()

  const columns = [
    { 
      title: 'Receipt Type',
      dataIndex: 'type',
    },
    {
      title: 'Date',
      dataIndex: 'timestamp',
    },
    {
      title: 'Sender',
      dataIndex: 'from'
    },
    {
      title: 'Recipient',
      dataIndex: 'to'
    },
    {
      title: 'Value',
      dataIndex: 'value'
    },
  ]

  useEffect(() => {
    actions.fetchReceipts(
      dispatch,
    );
  }, [dispatch, tokenOwnership, tokenRedemption, token, tokenDestroyed]);

  const handleTransactionsCSV = async () => {
    try {
      const csvData = data.map((d: any) => (`${d.type},${d.timestamp},${d.from},${d.to},"${d.value}"`))
      const csv = ['Type,Timestamp,From,To,Value', ...csvData].join('\n')
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "strat_transactions.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log(error)
    }
  };
  
  return (
    <>
      <Spin 
        spinning={isReceiptsLoading} 
        delay={500} 
        size='large'
    >
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
        <Typography.Title style={{ marginTop: 0 }}>Transaction History</Typography.Title>
        <Button
          style={{ height: '35px', width: '10rem' }}
          type="primary"
          onClick={handleTransactionsCSV}
          loading={isReceiptsLoading}
        >
          Download as CSV
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={data}
      />
      </Spin>
    </>
  )
}

export default TransactionTable
